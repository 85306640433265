<script>
import { mapGetters, mapState } from 'vuex'
import { IconRocket } from '@/icons'
import OnboardingCard from '../../OnboardingCard.vue'
import OnboardingHeader from '../../OnboardingHeader.vue'
import OnboardingHeading from '../../OnboardingHeading.vue'

export default {
  name: 'InstallationComplete',

  data() {
    return {
      loading: false,
      storeType: 'source',
    }
  },

  async mounted() {
    this.loading = true
    await this.$store.dispatch(`shop/init`)
    await this.$store.dispatch(`plan/init`, this.currentUserID)
    this.storeType = this.currentShop && this.currentShop.type
    this.loading = false
  },

  computed: {
    ...mapGetters('auth', ["currentUserID", "user"]),
    ...mapState('shop', [ 'currentShop' ]),
    ...mapState("plan", ["currentActivePlan"]),
  },

  components: {
    IconRocket,
    OnboardingCard,
    OnboardingHeader,
    OnboardingHeading,
  }
}
</script>

<template>
  <section class="onboarding v2 installation" v-if="!loading">
    <OnboardingHeader />

    <template>
      <OnboardingHeading heading="You're good to go!" />
      <p v-if="storeType === 'source'" class="onboarding-text m-t-2">
        Start sharing products and inventory with other stores
      </p>
      <p v-else class="onboarding-text m-t-2">
        You can now sync up to <span v-if="currentActivePlan && currentActivePlan.syncio_plan">{{ currentActivePlan.syncio_plan.sync_product_limit }}</span> products
      </p>

      <div class="m-t-4 installation">
        <OnboardingCard medium>
          <h5>What's next?</h5>

          <ul v-if="storeType === 'source'" class="onboarding-complete-list">
            <li>
              <span class="sequence">1</span>
              Click the “Stores” tab to invite and connect to other stores with Syncio
            </li>
            <li>
              <span class="sequence">2</span>
              Once connected, other stores can sync and sell your products.
            </li>
            <li>
              <span class="sequence">3</span>
              Syncio can then automatically update stock whenever an order is created.
              <br><br>
              We can also sync any updates you make too!
            </li>
          </ul>

          <ul v-else class="onboarding-complete-list">
            <li>
              <span class="sequence">1</span>
              Click the “Stores” tab to invite and connect to other stores with Syncio
            </li>
            <li>
              <span class="sequence">2</span>
              Once connected you can use the sync feature to copy and import products.
            </li>
          </ul>
        </OnboardingCard>

        <div class="outside-card-btn text-center">
          <v-btn
            @click="$router.replace('/dashboard')"
            class="onboarding-btn m-t-1 m-b-2"
            color="primary"
            elevation="0">
            Launch Syncio!
          </v-btn>
        </div>
      </div>
    </template>

    <div class="text-center m-t-1">
      <IconRocket />
    </div>
  </section>
</template>
